import React from "react";
// import Navbar from "../../components/Navbar/Navbar";
import Carousel from "../../components/Carousel/Carousel";
import Cards from "../../components/Cards/Cards";
import styles from "./Home.module.scss";

export default function Home() {
  return (
    <React.Fragment>
      {/* <Navbar /> */}
      <Carousel />
      <div className={styles.container}>
        <p className={styles.smallHeader}>
          Just bring your suitcase, move in anytime
        </p>
        <p className={styles.bigHeader}>
          Fully Renovated, Fully Furnished Designer Apartments
        </p>
        <div className={styles.longParagraph}>
          <p>
            Our apartments were designed with cool &amp; luxury finishes, comes
            complete with all kitchen, bath and bedroom supplies. Just minutes
            from city center, near to many international schools, shops,
            restaurants, fast food, banks, two private hospitals and
            supermarkets.
          </p>
          <p>
            Designer Apartments are newly renovated and come supplied with
            washing machine, clothes drier, microwave oven, pans, wok, blender,
            rice cooker, crockery, cutlery, etc.
          </p>
          <p>
            Entertainment systems include cable TV, home theatre system, DVD,
            flat panel TV in living room &amp; bedroom.
          </p>
        </div>
      </div>
      <Cards />
    </React.Fragment>
  );
}
