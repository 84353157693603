import React, { Component } from "react";
import styles from "../InventoryList.module.scss";

export class InformationNYSP extends Component {
  render() {
    return (
      <div className={styles.container}>
        <h1 className={styles.header}>Information</h1>
        <ul className={styles.lists}>
          <li>
            <strong>3,530sf</strong> duplex penthouse renovated to look like a
            stunning New York penthouse.
          </li>
          <li>Located at unit 13AA Impiana Ampang on the Waterfront</li>
        </ul>
        <div className={styles.row}>
          {/* 1:1 */}
          <div className={styles.column}>
            <ul className={styles.lists}>
              <p className={styles.secondHeader}>
                Entire upper floor is made up of:
              </p>
              <li>Master Bedroom</li>
              <li>Lounge-in-bedroom</li>
              <li>
                Master Sitting Room with 42&quot; TV, home theatre system, mini
                bar
              </li>
              <li>Walk in wardrobe</li>
              <li>Dressing Area</li>
              <li>Master bath with Jacuzzi &amp; separate shower</li>
              <li>Private balconies on front and rear of Master Suite</li>
            </ul>
          </div>
          {/* 1:2 */}
          <div className={styles.column}>
            <ul className={styles.lists}>
              <p className={styles.secondHeader}>More Info:</p>
              <li>Everything is brand new, renovated from floor to ceiling</li>
              <li>
                All appliances supplied -- TV 50&quot; + TV 42&quot;, home
                theatre system, washing machine, dryer, built in oven, built in
                microwave, large fridge, mini bar fridge, kitchen electrical
                appliances, crockery, cutlery, cooking utensils, etc
              </li>
              <li>
                4 rooms, each with en suite bathroom -- 3 BR downstairs, Master
                Suite upstairs
              </li>
              <li>Powder room for guests at entrance reception</li>
              <li>
                Huge imported barbeque set on balcony for parties in the sky
              </li>
              <li>
                Two kitchens -- dry kitchen which opens to the living room and
                wet kitchen in rear
              </li>
              <li>Stunning views of a huge lake and UK heights</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default InformationNYSP;
