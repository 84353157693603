import React from "react";
import styles from "./Impiana.module.scss";
import { Link } from "react-router-dom";

//React Reveal
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

//IMAGES
//Header Image
import headerImage from "../../images/Impiana/blackBeauty/BB-7.jpg";
//Impiana
import blackBeauty from "../../images/Impiana/blackBeauty/BB-12.jpg";
import NYSP from "../../images/Impiana/NYSP/NYSP-31.jpg";
import redAndBlack from "../../images/Impiana/redBlack/RB-7.jpg";

export default function Impiana() {
  return (
    <React.Fragment>
      {/* Image Header */}
      <div className={styles.imageHeader}>
        <Zoom>
          <img src={headerImage} alt="Impiana" />
        </Zoom>
        <Fade left>
          <h1 className={styles.imageText}>impiana</h1>
        </Fade>
      </div>

      <div className={styles.row}>
        <Fade left>
          {/* Card 1*/}
          <div className={styles.column}>
            <Link to="black-beauty">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={blackBeauty} alt="Black Beauty"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextShort}>Black Beauty</div>
                    <div className={styles.bottomText}>IMPIANA</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Fade>

        <Fade top>
          {/* Card 2*/}
          <div className={styles.column}>
            <Link to="new-york-style-penthouse">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={NYSP} alt="NYSP"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextLong}>
                      New York Style Penthouse
                    </div>
                    <div className={styles.bottomText}>Impiana</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Fade>

        <Fade bottom>
          {/* Card 3 */}
          <div className={styles.column}>
            <Link to="red-and-black">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={redAndBlack} alt="Red and Black"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextShort}>Red &amp; Black</div>
                    <div className={styles.bottomText}>Impiana</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Fade>
      </div>
    </React.Fragment>
  );
}
