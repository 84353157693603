//LIME GREEN SUITE
import React, { Component } from "react";

//Slideshow with Thumbnail API + CSS
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";

//CSS
import "./Slideshow.scss";

//IMAGES
import image1 from "../../images/KLCC/limeGreen/LG-1.jpg";
import image2 from "../../images/KLCC/limeGreen/LG-2.jpg";
import image3 from "../../images/KLCC/limeGreen/LG-3.jpg";
import image4 from "../../images/KLCC/limeGreen/LG-4.jpg";
import image5 from "../../images/KLCC/limeGreen/LG-5.jpg";
import image6 from "../../images/KLCC/limeGreen/LG-6.jpg";
import image7 from "../../images/KLCC/limeGreen/LG-7.jpg";
import image8 from "../../images/KLCC/limeGreen/LG-8.jpg";
import image9 from "../../images/KLCC/limeGreen/LG-9.jpg";
import image10 from "../../images/KLCC/limeGreen/LG-10.jpg";

const images = [
  {
    original: image1,
    thumbnail: image1,
  },
  {
    original: image2,
    thumbnail: image2,
  },
  {
    original: image3,
    thumbnail: image3,
  },
  {
    original: image4,
    thumbnail: image4,
  },
  {
    original: image5,
    thumbnail: image5,
  },
  {
    original: image6,
    thumbnail: image6,
  },
  {
    original: image7,
    thumbnail: image7,
  },
  {
    original: image8,
    thumbnail: image8,
  },
  {
    original: image9,
    thumbnail: image9,
  },
  {
    original: image10,
    thumbnail: image10,
  },
];

export class SlideshowLG extends Component {
  render() {
    return (
      <React.Fragment>
        <ImageGallery
          items={images}
          showPlayButton={false}
          showBullets={true}
          showIndex={true}
        />
      </React.Fragment>
    );
  }
}

export default SlideshowLG;
