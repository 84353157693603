import React, { Component } from "react";
import styles from "../Surrounding.module.scss";

export class Surrounding extends Component {
  render() {
    return (
      <div className={styles.container}>
        <h1 className={styles.header}>Surronding</h1>

        <div className={styles.row}>
          {/* 1:1 */}
          <div className={styles.column}>
            <ul className={styles.lists}>
              <p className={styles.secondHeader}>just next door</p>
              <li>Hotel with restaurants</li>
              <li>Bowling alley</li>
              <li>Convention facilities</li>
              <li>Daycare clinic</li>
            </ul>
          </div>
          {/* 1:2 */}
          <div className={styles.column}>
            <ul className={styles.lists}>
              <p className={styles.secondHeader}>within 5 minutes drive</p>
              <li>Many International Schools</li>
              <li>Gleneagles Specialist Hospital</li>
              <li>Golf Club with full clubbing facilities</li>
            </ul>
          </div>
          {/* 2:1 */}
          <div className={styles.column}>
            <ul className={styles.lists}>
              <p className={styles.secondHeader}>across the road</p>
              <li>8 Banks including HSBC</li>
              <li>Extensive restaurants</li>
              <li>Supermarket</li>
              <li>Post Office</li>
              <li>Shopping Complex</li>
              <li>Hair Salons</li>
              <li>Clinics &amp; Pharmacies</li>
              <li>Ampang Puteri Specialist Hospital</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Surrounding;
