import React, { Component } from "react";

//React Router
import { Link, Route, NavLink, HashRouter } from "react-router-dom";

//Icons
import { BsListUl } from "react-icons/bs";
import { FaWarehouse } from "react-icons/fa";
import { MdNearMe } from "react-icons/md";
import { FiMail } from "react-icons/fi";

//CSS
import styles from "./BlackBeauty.module.scss";
//Components
import NavbarOther from "../../../components/Navbar/NavbarOther";
import Slideshow from "../../../components/Slideshow/SlideshowBB";
//Component Pages
import InventoryList from "./InventoryList";
import Facilities from "./Facilities";
import Surrounding from "./Surrounding";

//Images
import KL from "../../../images/Others/KL.jpg";

export class BlackBeauty extends Component {
  render() {
    return (
      <React.Fragment>
        <NavbarOther />
        <Slideshow />
        <div className={styles.container}>
          <h1 className={styles.mainHeader}>Black Beauty</h1>
          {/* INFOS + MAP */}
          <div className={styles.row}>
            <div className={styles.columnInfos1}>
              {/* Monthly Rental, For Sale, Address, Contact */}
              <div className={styles.row}>
                {/* 1:1 */}
                <div className={styles.columnInfos2}>
                  <ul className={styles.lists}>
                    <p className={styles.secondHeader}>Monthly Rental</p>
                    <li>RM6,000</li>
                  </ul>
                </div>
                {/* 1:2 */}
                <div className={styles.columnInfos2}>
                  <ul className={styles.lists}>
                    <p className={styles.secondHeader}>For Sale</p>
                    <li>RM1.6 Million</li>
                  </ul>
                </div>
                {/* 2:1 */}
                <div className={styles.columnInfos2}>
                  <ul className={styles.lists}>
                    <p className={styles.secondHeader}>Address</p>
                    <li>
                      9th Floor, Impiana Condominium No 1, Tasik Ampang, Jalan
                      Hulu Kelang, 68000, Ampang, Selangor, Malaysia.
                    </li>
                  </ul>
                </div>
                {/* 2:2 */}
                <div className={styles.columnInfos2}>
                  <ul className={styles.lists}>
                    <p className={styles.secondHeader}>Contact</p>
                    <li>Jenny: +6012-517 5294</li>
                    <li>Datin Rabiah Hamid: +6017-888 5355</li>
                  </ul>
                </div>
              </div>
              {/* Booking/Enquiry Button */}
              <div className={styles.bookingBtn}>
                <Link to="/contacts">
                  <FiMail className={styles.bookingIcon} />
                  Booking/Enquiry
                </Link>
              </div>
            </div>
            {/* MAP */}
            <div className={styles.columnMap}>
              <iframe
                title="GoogleMap"
                className={styles.map}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9057.588430416175!2d101.73856142877729!3d3.160575834059249!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xee3624c9ffd457c7!2sImpiana%20On%20The%20Waterfront%20Condominium!5e0!3m2!1sen!2smy!4v1590938976294!5m2!1sen!2smy"
                frameBorder="0px"
              ></iframe>
            </div>
          </div>

          {/* 3x1 Column "Card View"  */}
          <div className={styles.row}>
            <div className={styles.columnWithBorder}>
              <p>
                <strong style={{ color: "red", fontSize: "20px" }}>
                  8 minutes
                </strong>{" "}
                to Petronas Twin Tower(KLCC), KL Tower, Five star hotels.
              </p>
            </div>
            <div className={styles.columnWithBorder}>
              <p>
                <strong style={{ color: "red", fontSize: "20px" }}>
                  5 minutes
                </strong>{" "}
                to 4 international schools, 3 private hospitals, 8 banks,
                several embassies, shopping complex, supermarkets, restaurants,
                bars.
              </p>
            </div>
            <div className={styles.column}>
              <p>
                All Cabinets are custom make and all flooring with new tiles and
                new wall tiles. Everything is <strong>BRAND NEW</strong>,
                renovated from floor to ceiling.
              </p>
            </div>
          </div>
          {/* Long paragraph */}
          <div className={styles.row}>
            <div className={styles.columnParagraph}>
              <p className={styles.paragraph}>
                Located at Impiana Ampang on the Waterfront, this unit is a
                spacious 2,140sfm, fully renovated with
                <strong> 5 Rooms, 4 Bathrooms &amp; 2 Kitchens.</strong>
                <br />
                <br />
                <strong>ALL SUPPLIED:</strong> Cool design with luxury finishes,
                comes complete with all kitchen, bath and bedroom supplies. Just
                minutes from Petronas Twin Towers, near to many international
                schools, shops, restaurants, fast food, banks, two private
                hospitals and supermarkets.
                <br />
                <br />
                This apartment is newly renovated and come supplied with washing
                machine, clothes drier, microwave oven, pans, wok, blender, rice
                cooker, crockery, cutlery, etc.
                <br />
                <br />
                Entertainment systems include cable TV, home theatre system,
                DVD, flat panel TV in living room + bedroom.
              </p>
            </div>
            {/* KL Tower Picture */}
            <div className={styles.columnPic}>
              <img src={KL} alt="Kuala Lumpur City" />
            </div>
          </div>
          {/* Sidebar + Sidebar Content */}
          <HashRouter>
            <div className={styles.row}>
              <div className={styles.columnSidebar}>
                <ul className={styles.sidebar}>
                  <p>Click here for more:</p>
                  <li>
                    <NavLink to="/inventory-list">
                      <BsListUl className={styles.icon} />
                      Inventory List
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/facilities">
                      <FaWarehouse className={styles.icon} />
                      Facilities
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/surrounding">
                      <MdNearMe className={styles.icon} />
                      Surrounding
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className={styles.columnContent}>
                <div className={styles.sideBarContent}>
                  <Route
                    exact
                    path="/inventory-list"
                    component={InventoryList}
                  />
                  <Route exact path="/facilities" component={Facilities} />
                  <Route exact path="/surrounding" component={Surrounding} />
                </div>
              </div>
            </div>
          </HashRouter>
        </div>
      </React.Fragment>
    );
  }
}

export default BlackBeauty;
