import React, { Component } from "react";

//React Router
import { Link, Route, NavLink, HashRouter } from "react-router-dom";

//Components
import NavbarOther from "../../../components/Navbar/NavbarOther";
import Slideshow from "../../../components/Slideshow/SlideshowLB";
//Component Pages
import Information from "./InformationLB";

//Icons
import { FiMail } from "react-icons/fi";
import { AiOutlineInfoCircle } from "react-icons/ai";

//CSS
import styles from "./LuxuryBlue.module.scss";

//Images
import KL from "../../../images/Others/KL2.jpg";

export class LuxuryBlue extends Component {
  render() {
    return (
      <React.Fragment>
        <NavbarOther />
        <Slideshow />
        <div className={styles.container}>
          <h1 className={styles.mainHeader}>Luxury Blue Suite</h1>
          {/* INFOS + MAP */}
          <div className={styles.row}>
            <div className={styles.columnInfos1}>
              {/* Monthly Rental, For Sale, Address, Contact */}
              <div className={styles.row}>
                {/* 1:1 */}
                <div className={styles.columnInfos2}>
                  <ul className={styles.lists}>
                    <p className={styles.secondHeader}>Monthly Rental</p>
                    <li>RM3,500</li>
                  </ul>
                </div>
                {/* 1:2 */}
                <div className={styles.columnInfos2}>
                  <ul className={styles.lists}>
                    <p className={styles.secondHeader}>For Sale</p>
                    <li>RM1.1M</li>
                  </ul>
                </div>
                {/* 2:1 */}
                <div className={styles.columnInfos2}>
                  <ul className={styles.lists}>
                    <p className={styles.secondHeader}>Address</p>
                    <li>Jalan Sultan Ismail, Kuala Lumpur</li>
                  </ul>
                </div>
                {/* 2:2 */}
                <div className={styles.columnInfos2}>
                  <ul className={styles.lists}>
                    <p className={styles.secondHeader}>Contact</p>
                    <li>Jenny: +6012-517 5294</li>
                    <li>Datin Rabiah Hamid: +6017-888 5355</li>
                  </ul>
                </div>
              </div>
              {/* Booking/Enquiry Button */}
              <div className={styles.bookingBtn}>
                <Link to="/contacts">
                  <FiMail className={styles.bookingIcon} />
                  Booking/Enquiry
                </Link>
              </div>
            </div>
            {/* MAP */}
            <div className={styles.columnMap}>
              <iframe
                className={styles.map}
                title="GoogleMap"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3983.7721686163354!2d101.707346!3d3.1546810000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6156c7ef65806964!2sVortex%20KLCC%20Apartments!5e0!3m2!1sen!2smy!4v1591015076393!5m2!1sen!2smy"
                frameBorder="0px"
              ></iframe>
            </div>
          </div>
          {/* 3x1 Column "Card View"  */}
          <div className={styles.row}>
            <div className={styles.columnWithBorder}>
              <p>
                Everything is supplied, Everything is{" "}
                <strong style={{ color: "red", fontSize: "20px" }}>
                  brand new!
                </strong>
              </p>
            </div>
            <div className={styles.columnWithBorder}>
              <p>
                Here&apos;s a quick description{" "}
                <strong style={{ color: "red", fontSize: "20px" }}>
                  - Just Bring Your Suitcase!
                </strong>
              </p>
            </div>
            <div className={styles.column}>
              <p>
                <strong style={{ color: "red", fontSize: "20px" }}>
                  Central Business
                </strong>{" "}
                district apartment
              </p>
            </div>
          </div>
          {/* Long paragraph */}
          <div className={styles.row}>
            <div className={styles.columnParagraph}>
              <p className={styles.paragraph}>
                <strong>Located at Vortex KLCC,</strong> Jalan Sultan Ismail,
                the Golden Triangle of Kuala Lumpur, just 5 minutes walking
                distance to KLCC Twin Towers and also 5 minutes walk to Pavilion
                Mall.
                <br />
                <br />
                <strong>NEW BUILDING:</strong> Completed 2016 and comes with
                FREEHOLD status, Kuala Lumpur&apos;s latest circular building,
                designed by U.S. architects Kohn, Pederson &amp; Fox.
              </p>
            </div>
            {/* KL Tower Picture */}
            <div className={styles.columnPic}>
              <img src={KL} alt="Kuala Lumpur City" />
            </div>
          </div>
          {/* Sidebar + Sidebar Content */}
          <HashRouter>
            <div className={styles.row}>
              <div className={styles.columnSidebar}>
                <ul className={styles.sidebar}>
                  <p>Click here for more:</p>
                  <li>
                    <NavLink to="/info">
                      <AiOutlineInfoCircle className={styles.icon} />
                      Information
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className={styles.columnContent}>
                <div className={styles.sideBarContent}>
                  <Route exact path="/info" component={Information} />
                </div>
              </div>
            </div>
          </HashRouter>
        </div>
      </React.Fragment>
    );
  }
}

export default LuxuryBlue;
