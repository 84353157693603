//NEW YORK STYLE PENTHOUSE
import React, { Component } from "react";

//Slideshow with Thumbnail API + CSS
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";

//CSS
import "./Slideshow.scss";

//IMAGES
import image1 from "../../images/Impiana/NYSP/NYSP-1.jpg";
import image2 from "../../images/Impiana/NYSP/NYSP-2.jpg";
import image3 from "../../images/Impiana/NYSP/NYSP-3.jpg";
import image4 from "../../images/Impiana/NYSP/NYSP-4.jpg";
import image5 from "../../images/Impiana/NYSP/NYSP-5.jpg";
import image6 from "../../images/Impiana/NYSP/NYSP-6.jpg";
import image7 from "../../images/Impiana/NYSP/NYSP-7.jpg";
import image8 from "../../images/Impiana/NYSP/NYSP-8.jpg";
import image9 from "../../images/Impiana/NYSP/NYSP-9.jpg";
import image10 from "../../images/Impiana/NYSP/NYSP-10.jpg";
import image11 from "../../images/Impiana/NYSP/NYSP-11.jpg";
import image12 from "../../images/Impiana/NYSP/NYSP-12.jpg";
import image13 from "../../images/Impiana/NYSP/NYSP-13.jpg";
import image14 from "../../images/Impiana/NYSP/NYSP-14.jpg";
import image15 from "../../images/Impiana/NYSP/NYSP-15.jpg";
import image16 from "../../images/Impiana/NYSP/NYSP-16.jpg";
import image17 from "../../images/Impiana/NYSP/NYSP-17.jpg";
import image18 from "../../images/Impiana/NYSP/NYSP-18.jpg";
import image19 from "../../images/Impiana/NYSP/NYSP-19.jpg";
import image20 from "../../images/Impiana/NYSP/NYSP-20.jpg";
import image21 from "../../images/Impiana/NYSP/NYSP-21.jpg";
import image22 from "../../images/Impiana/NYSP/NYSP-22.jpg";
import image23 from "../../images/Impiana/NYSP/NYSP-23.jpg";
import image24 from "../../images/Impiana/NYSP/NYSP-24.jpg";
import image25 from "../../images/Impiana/NYSP/NYSP-25.jpg";
import image26 from "../../images/Impiana/NYSP/NYSP-26.jpg";
import image27 from "../../images/Impiana/NYSP/NYSP-27.jpg";
import image28 from "../../images/Impiana/NYSP/NYSP-28.jpg";
import image29 from "../../images/Impiana/NYSP/NYSP-29.jpg";
import image30 from "../../images/Impiana/NYSP/NYSP-30.jpg";
import image31 from "../../images/Impiana/NYSP/NYSP-31.jpg";
import image32 from "../../images/Impiana/NYSP/NYSP-32.jpg";

const images = [
  {
    original: image1,
    thumbnail: image1,
  },
  {
    original: image2,
    thumbnail: image2,
  },
  {
    original: image3,
    thumbnail: image3,
  },
  {
    original: image4,
    thumbnail: image4,
  },
  {
    original: image5,
    thumbnail: image5,
  },
  {
    original: image6,
    thumbnail: image6,
  },
  {
    original: image7,
    thumbnail: image7,
  },
  {
    original: image8,
    thumbnail: image8,
  },
  {
    original: image9,
    thumbnail: image9,
  },
  {
    original: image10,
    thumbnail: image10,
  },
  {
    original: image11,
    thumbnail: image11,
  },
  {
    original: image12,
    thumbnail: image12,
  },
  {
    original: image13,
    thumbnail: image13,
  },
  {
    original: image14,
    thumbnail: image14,
  },
  {
    original: image15,
    thumbnail: image15,
  },
  {
    original: image16,
    thumbnail: image16,
  },
  {
    original: image17,
    thumbnail: image17,
  },
  {
    original: image18,
    thumbnail: image18,
  },
  {
    original: image19,
    thumbnail: image19,
  },
  {
    original: image20,
    thumbnail: image20,
  },
  {
    original: image21,
    thumbnail: image21,
  },
  {
    original: image22,
    thumbnail: image22,
  },
  {
    original: image23,
    thumbnail: image23,
  },
  {
    original: image24,
    thumbnail: image24,
  },
  {
    original: image25,
    thumbnail: image25,
  },
  {
    original: image26,
    thumbnail: image26,
  },
  {
    original: image27,
    thumbnail: image27,
  },
  {
    original: image28,
    thumbnail: image28,
  },
  {
    original: image29,
    thumbnail: image29,
  },
  {
    original: image30,
    thumbnail: image30,
  },
  {
    original: image31,
    thumbnail: image31,
  },
  {
    original: image32,
    thumbnail: image32,
  },
];

export class SlideshowNYSP extends Component {
  render() {
    return (
      <React.Fragment>
        <ImageGallery
          items={images}
          showPlayButton={false}
          showBullets={true}
          showIndex={true}
        />
      </React.Fragment>
    );
  }
}

export default SlideshowNYSP;
