import React, { Component } from "react";
import styles from "../Information.module.scss";

export class InformationLG extends Component {
  render() {
    return (
      <div className={styles.container}>
        <h1 className={styles.header}>Information</h1>
        <ul className={styles.lists}>
          <li>
            610sf On 29th Floor With Views To Pavilion &amp; Jalan Sultan Ismail
          </li>
          <li>2 Full Bedrooms, 1 Toilet</li>
          <li>
            Renovated From Head To Toe At Cost of <strong>RM100K++</strong>
          </li>
        </ul>
        <div className={styles.row}>
          {/* 1:1 */}
          <div className={styles.column}>
            <ul className={styles.lists}>
              <p className={styles.secondHeader}>all kitchen utensils</p>
              <li>Cooking Pans</li>
              <li>Kitchen Knives</li>
              <li>Crockery</li>
              <li>Cutlery</li>
              <li>Glassware</li>
              <li>Table Mats</li>
            </ul>
          </div>
          {/* 1:2 */}
          <div className={styles.column}>
            <ul className={styles.lists}>
              <p className={styles.secondHeader}>electrical appliances</p>
              <li>Toaster</li>
              <li>Kettle</li>
              <li>Blender</li>
              <li>Microwave</li>
              <li>Fridge</li>
              <li>Washing Machine</li>
            </ul>
          </div>
          {/* 2:1 */}
          <div className={styles.column}>
            <ul className={styles.lists}>
              <p className={styles.secondHeader}>entertainment</p>
              <li>50&quot; Flat Screen TV+ Blue-Ray player </li>
              <li>Custom Built Kitchen Bar/Dining</li>
              <li>Cabinets</li>
              <li>Queen Size Bed In Master Bedroom</li>
              <li>Twin Pullout Single Beds in Second Bedroom</li>
              <li>
                Yale Safe With Electronic Combination To Keep Your Valuables
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default InformationLG;
