import React, { Component } from "react";

//Components
import NavbarOther from "../../components/Navbar/NavbarOther";
import Form from "../../components/Form/Form";

//CSS
import styles from "./Contact.module.scss";

//React Icons
import { FiPhone } from "react-icons/fi";

//React Reveal
import Fade from "react-reveal/Fade";

export class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <NavbarOther />
        <div className={styles.bg}>
          <div className={styles.bgOverlay}>
            {/* Header */}
            <h1 className={styles.header}>Keep In Touch!</h1>

            {/* 2x1 Column by Row*/}
            <div className={styles.row}>
              {/* 1:1 */} {/* Information */}
              <div className={styles.columnParagraph}>
                <p className={styles.paragraph}>
                  We are committed to our clients. Should you have any inquiry
                  regarding our apartments, suites, our taste in design or
                  simply want to say Hello!, please fill up the form or contact
                  our representative below. We will revert back to you as soon
                  as possible.
                </p>
                <p className={styles.callUs}>Call/Whatsapp us:</p>
                <p className={styles.contactNumber}>
                  <FiPhone className={styles.icon} />
                  <a
                    href="https://api.whatsapp.com/send?phone=+60125175294"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Jenny +6012-517 5294
                  </a>
                </p>
                <p className={styles.contactNumber}>
                  <FiPhone className={styles.icon} />
                  <a
                    href="https://api.whatsapp.com/send?phone=+60178885355"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Datin Rabiah Hamid +6017-888 5355
                  </a>
                </p>
                <Fade left big>
                  <p className={styles.agentsAreWelcome}>Agents are welcome!</p>
                </Fade>
              </div>
              {/* 2:1 */} {/* Form */}
              <div className={styles.columnForm}>
                <Form />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contact;
