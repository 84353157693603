import React, { Component } from "react";

//React Router
import { Link, Route, NavLink, HashRouter } from "react-router-dom";

//Components
import NavbarOther from "../../../components/Navbar/NavbarOther";
import Slideshow from "../../../components/Slideshow/SlideshowNYSP";
//Component Pages
import Information from "./InformationNYSP";

//Icons
import { FiMail } from "react-icons/fi";
import { AiOutlineInfoCircle } from "react-icons/ai";

//CSS
import styles from "./NYSP.module.scss";

export class NYSP extends Component {
  render() {
    return (
      <React.Fragment>
        <NavbarOther />
        <Slideshow />
        <div className={styles.container}>
          <h1 className={styles.mainHeader}>New York Style Penthouse</h1>
          {/* INFOS + MAP */}
          <div className={styles.row}>
            <div className={styles.columnInfos1}>
              {/* Monthly Rental, For Sale, Address, Contact */}
              <div className={styles.row}>
                {/* 1:1 */}
                <div className={styles.columnInfos2}>
                  <ul className={styles.lists}>
                    <p className={styles.secondHeader}>Monthly Rental</p>
                    <li>RM8,000</li>
                  </ul>
                </div>
                {/* 1:2 */}
                <div className={styles.columnInfos2}>
                  <ul className={styles.lists}>
                    <p className={styles.secondHeader}>For Sale</p>
                    <li>RM2.0M</li>
                  </ul>
                </div>
                {/* 2:1 */}
                <div className={styles.columnInfos2}>
                  <ul className={styles.lists}>
                    <p className={styles.secondHeader}>Address</p>
                    <li>
                      Impiana Condominium No 1, Tasik Ampang, Jalan Hulu Kelang,
                      68000, Ampang, Selangor, Malaysia.
                    </li>
                  </ul>
                </div>
                {/* 2:2 */}
                <div className={styles.columnInfos2}>
                  <ul className={styles.lists}>
                    <p className={styles.secondHeader}>Contact</p>
                    <li>Jenny: +6012-517 5294</li>
                    <li>Datin Rabiah Hamid: +6017-888 5355</li>
                  </ul>
                </div>
              </div>
              {/* Booking/Enquiry Button */}
              <div className={styles.bookingBtn}>
                <Link to="/contacts">
                  <FiMail className={styles.bookingIcon} />
                  Booking/Enquiry
                </Link>
              </div>
            </div>
            {/* MAP */}
            <div className={styles.columnMap}>
              <iframe
                className={styles.map}
                title="GoogleMap"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15935.0012683875!2d101.749779!3d3.160378!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xee3624c9ffd457c7!2sImpiana%20On%20The%20Waterfront%20Condominium!5e0!3m2!1sen!2smy!4v1591019315107!5m2!1sen!2smy"
                frameBorder="0px"
              ></iframe>
            </div>
          </div>
          {/* 3x1 Column "Card View"  */}
          <div className={styles.row}>
            <div className={styles.columnWithBorder}>
              <p>
                <strong style={{ color: "red", fontSize: "20px" }}>
                  8 minutes
                </strong>{" "}
                to Petronas Twin Tower, KLCC, five star hotels
              </p>
            </div>
            <div className={styles.columnWithBorder}>
              <p>
                <strong style={{ color: "red", fontSize: "20px" }}>
                  5 minutes
                </strong>{" "}
                to 4 international schools, 3 private hospitals, 8 banks,
                several embassies, shopping complex, supermarkets, restaurants,
                bars
              </p>
            </div>
            <div className={styles.column}>
              <p>
                {" "}
                <strong style={{ color: "red", fontSize: "20px" }}>
                  Everything&apos;s new
                </strong>{" "}
                , renovated from floor to ceiling
              </p>
            </div>
          </div>
          {/* Sidebar + Sidebar Content */}
          <HashRouter>
            <div className={styles.row}>
              <div className={styles.columnSidebar}>
                <ul className={styles.sidebar}>
                  <p>Click here for more:</p>
                  <li>
                    <NavLink to="/info">
                      <AiOutlineInfoCircle className={styles.icon} />
                      Information
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className={styles.columnContent}>
                <div className={styles.sideBarContent}>
                  <Route exact path="/info" component={Information} />
                </div>
              </div>
            </div>
          </HashRouter>
        </div>
      </React.Fragment>
    );
  }
}

export default NYSP;
