import React from "react";
import styles from "./AllApartments.module.scss";
import { Link } from "react-router-dom";

//React Reveal
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
//IMAGES
//Header Image
import headerImage from "../../images/Impiana/NYSP/NYSP-32.jpg";
//Elements
import blueSuite from "../../images/Elements/blueSuite/BS-11.jpg";
import greenSuite from "../../images/Elements/greenSuite/GS-6.jpg";
import redSuite from "../../images/Elements/redSuite/RS-7.jpg";
import yellowSuite from "../../images/Elements/yellowSuite/YS-4.jpg";
//Impiana
import blackBeauty from "../../images/Impiana/blackBeauty/BB-12.jpg";
import NYSP from "../../images/Impiana/NYSP/NYSP-31.jpg";
import redAndBlack from "../../images/Impiana/redBlack/RB-7.jpg";
//KLCC
import limeGreen from "../../images/KLCC/limeGreen/LG-1.jpg";
import luxuryBlue from "../../images/KLCC/luxuryBlueSuite/LB-16.jpg";

export default function AllApartments() {
  return (
    <React.Fragment>
      {/* Image Header */}
      <div className={styles.imageHeader}>
        <Zoom>
          <img src={headerImage} alt="All Apartments" />
        </Zoom>
        <Fade left>
          <h1 className={styles.imageText}>all apartments</h1>
        </Fade>
      </div>

      <div className={styles.row}>
        {/* Card 1 */}
        <Fade left>
          <div className={styles.column}>
            <Link to="/blue-suite">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={blueSuite} alt="Blue Suite"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextShort}>Blue Suite</div>
                    <div className={styles.bottomText}>The Elements</div>
                    {/* SOLD tag*/}
                    <div className={styles.sold}>SOLD</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          {/* Card 2 */}
          <div className={styles.column}>
            <Link to="/green-suite">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={greenSuite} alt="Green Suite"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextShort}>Green Suite</div>
                    <div className={styles.bottomText}>The Elements</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          {/* Card 3 */}
          <div className={styles.column}>
            <Link to="/red-suite">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={redSuite} alt="Red Suite"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextShort}>Red Suite</div>
                    <div className={styles.bottomText}>The Elements</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Fade>

        <Fade right>
          {/* Card 4 */}
          <div className={styles.column}>
            <Link to="yellow-suite">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={yellowSuite} alt="Yellow Suite"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextShort}>Yellow Suite</div>
                    <div className={styles.bottomText}>The Elements</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          {/* Card 5 */}
          <div className={styles.column}>
            <Link to="black-beauty">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={blackBeauty} alt="Black Beauty"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextShort}>Black Beauty</div>
                    <div className={styles.bottomText}>IMPIANA</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          {/* Card 6*/}
          <div className={styles.column}>
            <Link to="new-york-style-penthouse">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={NYSP} alt="NYSP"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextLong}>
                      New York Style Penthouse
                    </div>
                    <div className={styles.bottomText}>Impiana</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Fade>

        <Fade left>
          {/* Card 7 */}
          <div className={styles.column}>
            <Link to="red-and-black">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={redAndBlack} alt="Red and Black"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextShort}>Red &amp; Black</div>
                    <div className={styles.bottomText}>Impiana</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          {/* Card 8 */}
          <div className={styles.column}>
            <Link to="lime-green-suite">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={limeGreen} alt="Lime Green Suite"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextLong}>Lime Green Suite</div>
                    <div className={styles.bottomText}>
                      klcc, jalan sultan ismail
                    </div>
                    {/* SOLD tag*/}
                    <div className={styles.sold}>SOLD</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          {/* Card 9 */}
          <div className={styles.column}>
            <Link to="luxury-blue-suite">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={luxuryBlue} alt="Luxury Blue Suite"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextLong}>Luxury Blue Suite</div>
                    <div className={styles.bottomText}>vortex klcc</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Fade>
      </div>
    </React.Fragment>
  );
}
