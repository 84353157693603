import React, { Component } from "react";

export class Facilities extends Component {
  render() {
    return (
      <div>
        <h1 style={{ fontSize: "30px" }}>Facilities</h1>
        <ul>
          <li style={{ padding: "10px" }}>Gym</li>
          <li style={{ padding: "10px" }}>Sauna</li>
          <li style={{ padding: "10px" }}>Children Play Room / Reading Room</li>
          <li style={{ padding: "10px" }}>Ping Pong Table / Room</li>
          <li style={{ padding: "10px" }}>Two Swimming Pools</li>
          <li style={{ padding: "10px" }}>BBQ Area</li>
          <li style={{ padding: "10px" }}>Function Room</li>
          <li style={{ padding: "10px" }}>Resident Guess</li>
          <li style={{ padding: "10px" }}>Studio Room</li>
          <li style={{ padding: "10px" }}>Convenience Store</li>
          <li style={{ padding: "10px" }}>Laundry Room</li>
          <li style={{ padding: "10px" }}>24-Hours Tied Security</li>
        </ul>
      </div>
    );
  }
}

export default Facilities;
