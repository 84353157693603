import React, { Component } from "react";
import styles from "../Information.module.scss";

export class InformationLB extends Component {
  render() {
    return (
      <div className={styles.container}>
        <h1 className={styles.header}>Information</h1>
        <ul className={styles.lists}>
          <li>820sf</li>
          <li>2 Full Bedrooms, 2 Toilets</li>
          <li>
            Renovated From Head To Toe At Cost of <strong>RM150K++</strong>
          </li>
          <li>On 33rd floor, with city views to Petronas Twin Towers KLCC</li>
        </ul>
        <div className={styles.row}>
          {/* 1:1 */}
          <div className={styles.column}>
            <ul className={styles.lists}>
              <p className={styles.secondHeader}>all kitchen utensils</p>
              <li>Cooking Pans</li>
              <li>Kitchen Knives</li>
              <li>Crockery</li>
              <li>Cutlery</li>
              <li>Glassware</li>
              <li>Table Mats</li>
            </ul>
          </div>
          {/* 1:2 */}
          <div className={styles.column}>
            <ul className={styles.lists}>
              <p className={styles.secondHeader}>electrical appliances</p>
              <li>Electric Water Dispenser</li>
              <li>Toaster</li>
              <li>Microwave</li>
              <li>Fridge</li>
              <li>Washing Machine</li>
            </ul>
          </div>
          {/* 2:1 */}
          <div className={styles.column}>
            <ul className={styles.lists}>
              <p className={styles.secondHeader}>entertainment</p>
              <li>65&quot; Flat Screen TV+ Blueray player </li>
              <li>Custom Built Kitchen Bar/Dining</li>
              <li>Cabinets</li>
              <li>King Size Bed In Master Bedroom</li>
              <li>Two Single Beds In Second Bedroom</li>
              <li>En-suite bathroom attached to each bedroom</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default InformationLB;
