import React, { Component } from "react";
import styles from "../InventoryList.module.scss";

export class InventoryList extends Component {
  render() {
    return (
      <div className={styles.container}>
        <h1 className={styles.header}>Inventory List</h1>

        <div className={styles.row}>
          <div className={styles.firstRow}>
            {/* 1:1 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>entranceway</p>
                <li>1x Sofa Bench</li>
                <li>
                  1x Wall Mirror with wooden frame &amp; light behind mirror +
                  wall lights (2units)
                </li>
                <li>1x Female Statue</li>
                <li>1x Console</li>
                <li>1x Basket with 2 umbrellas</li>
              </ul>
            </div>
            {/* 1:2 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>
                  walk way area to meeting room
                </p>
                <li>1x Built-in Shoe Cabinet</li>
                <li>1x Wall Picture</li>
              </ul>
            </div>
          </div>
          <div className={styles.secondRow}>
            {/* 2:1 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>dining area</p>
                <li>1x Extendable Glass Dining Table (accommodates 10pax)</li>
                <li>1x 2.5HP Ceiling Cassette York Air Conditioner</li>
                <li>
                  1x Wall Full Mirror with Wooden Frame + 2 Consoles with Vases
                </li>
                <li>1x Black &amp; White 6&quot; x 10&quot; Rug</li>
              </ul>
            </div>
            {/* 2:2 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>living hall</p>
                <li>
                  1x &quot;3 Seater&quot; 11&apos; Long Sofa with Side Table
                </li>
                <li>2x Single Chiars(White) with Loose Cushions(Red)</li>
                <li>1x Single Arm Chair(Red) Arne Jacobsen design</li>
                <li>1x Standing Light(Red) with Shade(White)</li>
                <li>1x Square Coffee Table with Glass Top</li>
                <li>1x Rug (7&apos; x 10&apos;)</li>
                <li>
                  1x Built-in Shelf (White) decorated with 2 Vases (Yellow)
                </li>
                <li>3x Vases (White)</li>
                <li>1x Wall Picture</li>
                <li>1 set Custom made TV wall unit with down lights</li>
                <li>1x 42” TV – LG with Sound Theater System, 4 speakers</li>
                <li>1x DVD Player – LG &amp; ASTRO Decoder</li>
                <li>1x 2.5 HP ceiling Cassette York Air Conditioner</li>
                <li>1 set Sheer Curtain (White) &amp; Night Curtain (Black)</li>
              </ul>
            </div>
          </div>
          <div className={styles.thirdRow}>
            {/* 3:1 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>powder room</p>
                <li>1x Full Wall Mirror</li>
                <li>1 set Round Black Basin with red cabinet, Towel Holder</li>
                <li>1 set Instant Water Heater</li>
              </ul>
            </div>
            {/* 3:2 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>laundry area</p>
                <li>
                  1 set Full Length Built-in Cabinet with multiple compartments,
                  inclusive of Clothes Hanging Compartment &amp; Janitor’s store
                </li>
                <li>1x Upright Freezer – ELBA</li>
                <li>1x Iron &amp; Ironing Board - TEFAL</li>
                <li>1x Washing machine - BOSCH</li>
                <li>1x Dryer - BOSCH</li>
              </ul>
            </div>
          </div>
          <div className={styles.fourthRow}>
            {/* 4:1 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>outside common corridor</p>
                <li>
                  1 set Black Built-in Cabinet and Shelf with full Wall Mirror
                </li>
              </ul>
            </div>
            {/* 4:2 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>balcony area</p>
                <li>2x Rattan Round Chairs with Cushions</li>
                <li>1x Rattan Round Table with Glass Top</li>
                <li>1x Built-in 2 Seater Sofa with Cushion</li>
                <li>1x Electric BBQ Set</li>
                <li>1x Extendable BBQ Cabinet (Black)</li>
              </ul>
            </div>
          </div>
          <div className={styles.fifthRow}>
            {/* 5:1 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>kitchen island</p>
                <li>
                  1x Solid top island counter with Sink &amp; cabinet underneath
                  (Accommodates 4 pax)
                </li>
                <li>4x Bar Stools</li>
              </ul>
            </div>
            {/* 5:2 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>show kitchen (kitchen 1)</p>
                <li>
                  1 set Floor-to-ceiling Built-in Cabinet (Mirror Black
                  finished)
                </li>
                <li>1 set Electric Hob and Cooker Hood - TEKA</li>
                <li>
                  1x Side-by-side black Refrigerator twin door with Ice maker
                </li>
                <li>1x Kettle - TEFAL</li>
                <li>1x Bread Toaster - TEFAL</li>
                <li>1x Juice Mixer - TEFAL</li>
                <li>1x Microwave - PANASONIC</li>
                <li>1x Built-in Electric Oven – TUSCANI</li>
              </ul>
            </div>
          </div>
          <div className={styles.sixthRow}>
            {/* 6:1 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>
                  production kitchen (second kitchen)
                </p>
                <li>1x White Tall Round Bar Table with 2 stools</li>
                <li>
                  1 set Black &amp; White Built-in Kitchen Cabinet with Solid
                  top
                </li>
                <li>1x Built-in glass Gas Hob - ALCOR</li>
                <li>1x Cooker Hood – LEBENSSTIL (German)</li>
                <li>1x Gas cylinder</li>
                <li>1x Rice Cooker - HITACHI</li>
                <li>1x Blender - TEFAL</li>
                <li>
                  1 set Stainless Steel Sink with 2 compartments, dustbin
                  underneath
                </li>
                <li>1x Water filter -- Hot and Cold Water Dispenser – MAGIC</li>
                <li>1x 2HP Air-conditioner</li>
              </ul>
            </div>
            {/* 6:2 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>walk-in wardrobe</p>
                <li>1 set Clothes Hanging Compartment with Shelf</li>
                <li>1 set Wall Mirror</li>
              </ul>
            </div>
          </div>
          <div className={styles.seventhRow}>
            {/* 7:1 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>
                  1ST ROOM (PRIVATE MEETING ROOM CUM GUEST ROOM)
                </p>
                <li>1x 3 Seater/ 8’ long Sofa with Fabric cover </li>
                <li>1x Round Coffee Table with Glass Top</li>
                <li>
                  1x Built-in Table with shelf and drawer with Minibar fridge
                </li>
                <li>1 set Sheer Curtain and Night Curtain (Cream)</li>
                <li>1x 1.5HP Air-conditioner with remote control</li>
              </ul>
            </div>
            {/* 7:2 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>master bathroom</p>
                <li>
                  1x Round Black &amp; White Basin with Black cabinet, full
                  Mirror
                </li>
                <li>1x Hand Shower</li>
                <li>1x Rain Shower</li>
              </ul>
            </div>
          </div>
          <div className={styles.eigthRow}>
            {/* 8:1 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>
                  2nd bedroom (teenager&apos;s room
                </p>
                <li>
                  1x Built-in Study Table with drawers and cabinet for 2pax
                </li>
                <li>2x Study Chairs</li>
                <li>1x Dressing Table with drawer and wall mirror</li>
                <li>1x Built-in Modern Wardrobe</li>
                <li>
                  2x Custom made Single Beds with side Table &amp; headboard
                </li>
                <li>1x Single Mattress with bed sheet (White)</li>
                <li>4x Pillow with covers (White)</li>
                <li>1x Table Lamp with Shade (White)</li>
                <li>1 set Night Curtain (Red)</li>
                <li>1x 1.5HP Air-conditioner</li>
              </ul>
            </div>
            {/* 8:2 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>
                  3rd bedroom (master bedroom)
                </p>
                <li>
                  1 set Platform Bed with 2 Side Tables (floating light
                  underneath)
                </li>
                <li>2x Table Lamps with Shades (Grey &amp; White)</li>
                <li>2x Wall Lights</li>
                <li>
                  1x King size 8” Mattress with bed sheet (White) with Bed
                  runner
                </li>
                <li>4x Pillows with covers (White)</li>
                <li>
                  1 set Built-in Shelf (White) decorated with 4 units of black
                  &amp; white Vase
                </li>
                <li>
                  2x Arm Chairs with Leg rests (Grey &amp; Black) 1 unit Side
                  Table
                </li>
                <li>1x standing Light with Shade (White)</li>
                <li>1x TV Cabinet (Black)</li>
                <li>1x Mini Bar/ Fridge</li>
                <li>1x 40” TV – Toshiba with ASTRO Decoder</li>
                <li>1x DVD Player - LG</li>
                <li>
                  1 set Full Mirror Dressing Table with Black Stool &amp; down
                  light
                </li>
                <li>1x Built in Cabinet with Full length Mirror Doors</li>
                <li>1 set Sheer Curtain (White) with Night Curtain (Black)</li>
                <li>1x 2 HP Air-conditioner</li>
              </ul>
            </div>
          </div>
          <div className={styles.ninthRow}>
            {/* 9:1 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>
                  4TH BEDROOM (CHILDREN’S BEDROOM)
                </p>
                <li>1x Study Table &amp; Chair (Blue &amp; White)</li>
                <li>1x Built-in cabinet and shelf (Blue &amp; White)</li>
                <li>1x Single Bed + one pull-out bed</li>
                <li>
                  2x Single Mattress with bed sheet (White) with Pillow cover
                  (White)
                </li>
                <li>2x Single Seaters for Children</li>
                <li>
                  1x Built-in Wardrobe with full length Mirror (Blue &amp;
                  White)
                </li>
                <li>1 set Night Curtain (Blue)</li>
                <li>1x 1HP Air-conditioner with remote control</li>
              </ul>
            </div>
            {/* 9:2 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>5th bedroom (guest room)</p>
                <li>1 set Built-in Cabinet (White)</li>
                <li>
                  1x Single Mattress with bed sheet &amp; 2 Pillows with covers
                  (White)
                </li>
                <li>1x 1HP Air-conditioner</li>
              </ul>
            </div>
          </div>
          <div className={styles.tenthRow}>
            {/* 10:1 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>common bathroom</p>
                <li>
                  1 set White Oval Basin with Black cabinet and full Wall Mirror
                </li>
                <li>1x Hand Shower</li>
                <li>1x Rain Shower</li>
              </ul>
            </div>
            {/* 10:2 */}
            <div className={styles.column}>
              <ul className={styles.lists}>
                <p className={styles.secondHeader}>4th bathroom</p>
                <li>1x White Basin with Wall Mirror, Towel Rack</li>
                <li>1 set Instant Water Heater with Hand Shower</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InventoryList;
