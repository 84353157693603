import React from "react";

//CSS
import styles from "./Footer.module.scss";

//React Icons
import { GoHeart } from "react-icons/go";

export default function Footer() {
  return (
    <footer className={styles.container}>
      <p className={styles.text1}>
        We <GoHeart className={styles.iconHeart} /> Kuala Lumpur
      </p>

      <p className={styles.text2}>
        © 2015 - 2018 DESIGNER APARTMENTS KUALA LUMPUR
      </p>
    </footer>
  );
}
