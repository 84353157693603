import React from "react";

//React Router Dom
import { Link } from "react-router-dom";

//React Reveal
import Fade from "react-reveal/Fade";

//Images
import cardElements from "../../images/Elements/redSuite/RS-5.jpg";
import cardImpiana from "../../images/Impiana/NYSP/NYSP-6.jpg";
import cardKlcc from "../../images/KLCC/luxuryBlueSuite/LB-9.jpg";

//CSS
import styles from "./Cards.module.scss";

export default function Cards() {
  return (
    <React.Fragment>
      {/* //HEADER */}
      <p className={styles.smallHeader}>5 star interior design by DA</p>
      <p className={styles.bigHeader}>Apartments &amp; Suites</p>
      <p className={styles.sentence}>
        We believe our work speaks for itself. Browse our most recent properties
        below.
      </p>

      {/* //CARDS */}
      <div className={styles.row}>
        {/* Card 1:1  */}
        <Fade top>
          <div className={styles.column}>
            <div className={styles.cards}>
              <div className={styles.imagesContainerAll}>
                <img src={cardElements} alt="Elements"></img>
                <img src={cardImpiana} alt="Impiana"></img>
                <img src={cardKlcc} alt="KLCC"></img>
              </div>
              <div className={styles.descContainer}>
                <h5>ALL APARTMENTS</h5>
                <Link style={{ textDecoration: "none" }} to="all-apartments">
                  <p className={styles.discBtn}>Discover Here &gt; </p>
                </Link>
              </div>
            </div>
          </div>
        </Fade>
        {/* Card 2:1 */}
        <Fade right>
          <div className={styles.column}>
            <div className={styles.cards}>
              <div className={styles.imagesContainer}>
                <img src={cardElements} alt="Elements"></img>
              </div>
              <div className={styles.descContainer}>
                <h5>THE ELEMENTS</h5>
                <Link style={{ textDecoration: "none" }} to="the-elements">
                  <p className={styles.discBtn}>Discover Here &gt; </p>
                </Link>
              </div>
            </div>
          </div>
        </Fade>

        {/* Card 1:2  */}
        <Fade left>
          <div className={styles.column}>
            <div className={styles.cards}>
              <div className={styles.imagesContainer}>
                <img src={cardImpiana} alt="Impiana"></img>
              </div>
              <div className={styles.descContainer}>
                <h5>IMPIANA</h5>
                <Link style={{ textDecoration: "none" }} to="impiana">
                  <p className={styles.discBtn}>Discover Here &gt; </p>
                </Link>
              </div>
            </div>
          </div>
        </Fade>

        {/* Card 2:2  */}
        <Fade bottom>
          <div className={styles.column}>
            <div className={styles.cards}>
              <div className={styles.imagesContainer}>
                <img src={cardKlcc} alt="KLCC"></img>
              </div>
              <div className={styles.descContainer}>
                <h5>KLCC</h5>
                <Link style={{ textDecoration: "none" }} to="klcc">
                  <p className={styles.discBtn}>Discover Here &gt; </p>
                </Link>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </React.Fragment>
  );
}
