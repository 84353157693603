//Navbar with scroll effects
//This navbar is transparent at first, and once scrolled will changes colour

import React, { Component } from "react";
//COMPANY LOGO
import logoWhite from "../../images/logo/DALogoWhite.svg";
import logoBlack from "../../images/logo/DALogoBlack.svg";
//REACT-ROUTER-DOM
import { Link } from "react-router-dom";
//CSS
import styles from "./NavbarMain.module.scss";

export class NavbarHomePage extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.hamburgerWrapper1 = React.createRef();
    this.hamburgerWrapper2 = React.createRef();
    this.hamburgerWrapper3 = React.createRef();
    this.state = {
      handleBurger: false,
      handleApartment: false,
      navBackground: "",
      logoImages: [],
      logoBgColor: "",
    };
    this.showApartmentMenu = this.showApartmentMenu.bind(this);
    this.hideApartmentMenu = this.hideApartmentMenu.bind(this);
  }

  // HANDLE HAMBURGER BTN
  handleBurger = () => {
    const hamburgerAnimation1 = this.hamburgerWrapper1.current;
    const hamburgerAnimation2 = this.hamburgerWrapper2.current;
    const hamburgerAnimation3 = this.hamburgerWrapper3.current;
    hamburgerAnimation1.classList.toggle(styles.barChg1);
    hamburgerAnimation2.classList.toggle(styles.barChg2);
    hamburgerAnimation3.classList.toggle(styles.barChg3);

    if (this._isMounted) {
      this.setState({
        handleBurger: !this.state.handleBurger,
      });
    }
  };

  // HANDLE APARTMENT DROPDOWN
  showApartmentMenu = () => {
    if (this._isMounted) {
      this.setState({ handleApartment: true }, () => {
        document.addEventListener("click", this.hideApartmentMenu);
      });
    }
  };

  hideApartmentMenu = () => {
    if (this._isMounted) {
      this.setState({ handleApartment: false }, () => {
        document.removeEventListener("click", this.hideApartmentMenu);
      });
    }
  };

  //updates apartment dropdown when screen is resized
  updateApartment() {
    if (window.innerWidth < 800) {
      if (this._isMounted) {
        this.setState({
          handleApartment: false,
        });
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("resize", this.updateApartment.bind(this));

    document.addEventListener("scroll", () => {
      //Handle Navbar background color when scrolling
      const backgroundcolor = window.scrollY < 150 ? "" : "white";
      this.setState({ navBackground: backgroundcolor });
      //Handle Logo when scrolling
      const logoContainer = window.scrollY < 150 ? logoWhite : logoBlack;
      this.setState({ logoImages: logoContainer });
      const logoBackgroundColor =
        window.scrollY < 150 ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0)";
      this.setState({ logoBgColor: logoBackgroundColor });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <nav
        className={styles.navbar}
        style={{ backgroundColor: `${this.state.navBackground}` }}
      >
        {/* Logo */}
        <Link to="/">
          <img
            className={styles.DALogo}
            src={this.state.logoImages}
            style={{ backgroundColor: `${this.state.logoBgColor}` }}
            alt="Designer Apartments"
          />
        </Link>

        {/* Hamburger button */}
        <div className={styles.hamburger} onClick={this.handleBurger}>
          <div ref={this.hamburgerWrapper1} className={styles.bar1}></div>
          <div ref={this.hamburgerWrapper2} className={styles.bar2}></div>
          <div ref={this.hamburgerWrapper3} className={styles.bar3}></div>
        </div>

        <ul
          className={
            this.state.handleBurger
              ? `${styles.navLink} ${styles.showNav}`
              : `${styles.navLink}`
          }
        >
          {/* HOME */}
          <li>
            <Link to="/" onClick={this.handleBurger}>
              Home
            </Link>
          </li>

          {/* APARTMENTS */}

          {/* Apartment Button */}
          <div>
            <p className={styles.apartmentBtn} onClick={this.showApartmentMenu}>
              Apartments
            </p>
            {/* Apartment Button END */}

            {/* Apartment Dropdown */}
            <div>
              <ul
                className={
                  this.state.handleApartment
                    ? `${styles.dropdownMenu} ${styles.dropdownAnimation}`
                    : `${styles.dropdownMenu} ${styles.popupAnimation}`
                }
              >
                <li className={styles.apartmentLinks}>
                  <Link to="all-apartments" onClick={this.handleBurger}>
                    all apartments
                  </Link>
                </li>
                <li>
                  <Link to="the-elements" onClick={this.handleBurger}>
                    the elements
                  </Link>
                </li>
                <li>
                  <Link to="impiana" onClick={this.handleBurger}>
                    impiana
                  </Link>
                </li>
                <li>
                  <Link to="klcc" onClick={this.handleBurger}>
                    klcc
                  </Link>
                </li>
              </ul>
            </div>
            {/* Apartment Dropdown END */}
          </div>

          {/* CONTACTS */}
          <li>
            <Link to="/contacts" onClick={this.handleBurger}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default NavbarHomePage;
