import React, { useState } from "react";
import { Link } from "react-router-dom";

//wont be able to overwrite Bootstrap css with CSS module
import "./Carousel.scss";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

// Slides
import slide1 from "../../images/carousel/carousel-1.jpg";
import slide2 from "../../images/carousel/carousel-2.jpg";
import slide3 from "../../images/carousel/carousel-3.jpg";
import slide4 from "../../images/carousel/carousel-4.jpg";
import slide5 from "../../images/carousel/carousel-5.jpg";

const items = [
  {
    src: slide1,
    altText: "Red & Black",
    captionHead:
      "Agents are welcome ! \n Fully renovated, furnished apartments with luxury finishes",
    caption: (
      <Link className="redBlack" to="/red-and-black">
        Explore the Red &amp; Black
      </Link>
    ),
  },
  {
    src: slide2,
    altText: "Black Beauty",
    captionHead: "Black Beauty Impiana Condominium Balcony View",
    caption: (
      <Link className="blackBeauty" to="/black-beauty">
        Explore the Black Beauty
      </Link>
    ),
  },
  {
    src: slide3,
    altText: "Yellow Suite",
    captionHead: "Yellow Suite The Elements Sky Pool",
    caption: (
      <Link className="yellowSuite" to="/yellow-suite">
        Explore the Yellow Suite
      </Link>
    ),
  },
  {
    src: slide4,
    altText: "Red Suite",
    captionHead: "Red Suite The Elements Balcony View",
    caption: (
      <Link className="redSuite" to="red-suite">
        Explore the Red Suite
      </Link>
    ),
  },
  {
    src: slide5,
    altText: "NY Style Penthouse",
    captionHead: "New York Style Penthouse Balcony View",
    caption: (
      <Link className="NYSP" to="/new-york-style-penthouse">
        Explore New York Style Penthouse
      </Link>
    ),
  },
];

const Carousell = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className="slideContainer"
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img className="slides" src={item.src} alt={item.altText} />

        <CarouselCaption
          className="captionAnimation"
          captionHeader={item.captionHead}
          captionText={item.caption}
        ></CarouselCaption>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      ride={"carousel"}
      interval={6000}
      pause={false}
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

export default Carousell;
