import React, { Component } from "react";

//CSS
import styles from "./Form.module.scss";

//Axios
import axios from "axios";

//React Promise Tracker (For loading purposes)
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";

//Loading Message
const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <p className={styles.loading}>Loading! Please wait a moment...</p>
    )
  );
};

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      phoneNo: "",
      email: "",
      apartmentsInterested: "",
      intentions: "",
      enquiries: "",
    };
  }

  onNameChange(event) {
    this.setState({ fullName: event.target.value });
  }

  onPhoneChange(event) {
    this.setState({ phoneNo: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }
  onApartmentInterestedChange(event) {
    this.setState({ apartmentsInterested: event.target.value });
  }

  onIntentionsChange(event) {
    this.setState({ intentions: event.target.value });
  }

  onEnquiriesChange(event) {
    this.setState({ enquiries: event.target.value });
  }

  //Handling Submit Button
  handleSubmit(e) {
    e.preventDefault();
    trackPromise(
      axios({
        method: "POST",
        url: "/.netlify/functions/sendEmail",
        data: this.state,
      }).then((response) => {
        console.log("response", response);

        if (response.data.status === "success") {
          alert("Message Sent! We will get back to as soon as we can.");

          this.resetForm();
        } else if (response.data.status === "fail") {
          alert(
            "Oops! Message failed to send. You can try calling/whatsapp us"
          );
        }
      })
    );
  }

  //Reset the form input fields empty after successfully submitted
  resetForm() {
    this.setState({
      fullName: "",
      phoneNo: "",
      email: "",
      apartmentsInterested: "",
      intentions: "",
      enquiries: "",
    });
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit.bind(this)} method="POST">
          <label className={styles.label} htmlFor="fullName">
            Full Name:
          </label>
          <span className={styles.required}>*</span>
          <span className={styles.example}>(All CAPS, eg: JOHN SMITH)</span>
          <input
            type="text"
            pattern="[A-Z ]{2,50}"
            placeholder="Your name..."
            id="fullName"
            value={this.state.fullName}
            onChange={this.onNameChange.bind(this)}
            required
          />
          {/* Phone Number */}
          <label className={styles.label} htmlFor="phoneNo">
            Phone Number:
          </label>
          <span className={styles.required}>*</span>
          <span className={styles.example}>
            (eg: +CountryCode12345678 | +60123456789)
          </span>
          <input
            type="tel"
            pattern="[+]{1}[0-9]{1,10}[0-9]{1,20}"
            placeholder="Your Phone Number..."
            id="phoneNo"
            value={this.state.phoneNo}
            onChange={this.onPhoneChange.bind(this)}
            required
          />
          {/* Email Address */}
          <label className={styles.label} htmlFor="email">
            Email Address:
          </label>
          <span className={styles.required}>*</span>
          <span className={styles.example}>(eg: abcd@yahoo.com)</span>
          <input
            type="email"
            placeholder="Your Email Address..."
            id="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            value={this.state.email}
            onChange={this.onEmailChange.bind(this)}
            required
          />
          {/* Apartment Interested */}
          <label className={styles.label} htmlFor="apartmentsInterested">
            Apartments Interested:
          </label>
          <span className={styles.required}>*</span>
          <select
            id="apartmentsInterested"
            value={this.state.apartmentsInterested}
            onChange={this.onApartmentInterestedChange.bind(this)}
            required
          >
            <option value="" disabled>
              Select your option...
            </option>
            <option value="The Elements">The Elements</option>
            <option value="Impiana">Impiana</option>
            <option value="KLCC">KLCC</option>
          </select>
          {/* Intentions */}
          <label className={styles.label} htmlFor="intentions">
            Intentions:
          </label>
          <span className={styles.required}>*</span>
          <select
            id="intentions"
            value={this.state.intentions}
            onChange={this.onIntentionsChange.bind(this)}
            required
          >
            <option value="" disabled>
              Select your option...
            </option>
            <option value="Rent">Rent</option>
            <option value="Purchase">Purchase</option>
            <option value="Invest">Invest</option>
          </select>
          {/* Enquiries */}
          <label className={styles.label} htmlFor="enquiries">
            Enquiries:
          </label>
          <textarea
            placeholder="Dont be shy, talk to us..."
            id="enquiries"
            value={this.state.enquiries}
            cols="65"
            rows="4"
            style={{ padding: "10px" }}
            onChange={this.onEnquiriesChange.bind(this)}
          />
          {/* Submit Button */}
          <LoadingIndicator />
          <button className={styles.submit}>SUBMIT!</button>
        </form>
      </div>
    );
  }
}

export default Form;
