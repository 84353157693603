import React from "react";

//Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
//Pages
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import AllApartments from "./pages/AllApartments/AllApartments";
import Elements from "./pages/Elements/Elements";
import Impiana from "./pages/Impiana/Impiana";
import Klcc from "./pages/Klcc/Klcc";
//Pages/Apartments
import BlueSuite from "./pages/Apartments/BlueSuite/BlueSuite";
import GreenSuite from "./pages/Apartments/GreenSuite/GreenSuite";
import RedSuite from "./pages/Apartments/RedSuite/RedSuite";
import YellowSuite from "./pages/Apartments/YellowSuite/YellowSuite";
import BlackBeauty from "./pages/Apartments/BlackBeauty/BlackBeauty";
import NYSP from "./pages/Apartments/NYSP/NYSP";
import RedBlack from "./pages/Apartments/RedBlack/RedBlack";
import LimeGreen from "./pages/Apartments/LimeGreen/LimeGreen";
import LuxuryBlue from "./pages/Apartments/LuxuryBlue/LuxuryBlue";
//Pages/Error
import Error from "./pages/Error/Error";

//Components
import NavbarMain from "./components/Navbar/NavbarMain";
import Footer from "./components/Footer/Footer";
import { Route, Switch } from "react-router-dom";

export class App extends React.Component {
  componentDidMount() {
    //to prevent the browser from flying everywhere, when browser refreshes
    window.scrollTo(1, 1);
  }

  render() {
    return (
      <React.Fragment>
        <NavbarMain />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/all-apartments" component={AllApartments} />
          <Route exact path="/the-elements" component={Elements} />
          <Route exact path="/impiana" component={Impiana} />
          <Route exact path="/klcc" component={Klcc} />
          <Route exact path="/contacts" component={Contact} />
          <Route exact path="/blue-suite" component={BlueSuite} />
          <Route exact path="/green-suite" component={GreenSuite} />
          <Route exact path="/red-suite" component={RedSuite} />
          <Route exact path="/yellow-suite" component={YellowSuite} />
          <Route exact path="/black-beauty" component={BlackBeauty} />
          <Route exact path="/new-york-style-penthouse" component={NYSP} />
          <Route exact path="/red-and-black" component={RedBlack} />
          <Route exact path="/lime-green-suite" component={LimeGreen} />
          <Route exact path="/luxury-blue-suite" component={LuxuryBlue} />
          <Route component={Error} />
        </Switch>
        <Footer />
      </React.Fragment>
    );
  }
}

export default App;
