import React from "react";
import styles from "./Klcc.module.scss";
import { Link } from "react-router-dom";

//React Reveal
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

//IMAGES
//Header Image
import headerImage from "../../images/KLCC/luxuryBlueSuite/LB-9.jpg";
//KLCC
import limeGreen from "../../images/KLCC/limeGreen/LG-1.jpg";
import luxuryBlue from "../../images/KLCC/luxuryBlueSuite/LB-16.jpg";

export default function Klcc() {
  return (
    <React.Fragment>
      {/* Image Header */}
      <div className={styles.imageHeader}>
        <Zoom>
          <img src={headerImage} alt="All Apartments" />
        </Zoom>
        <Fade left>
          <h1 className={styles.imageText}>klcc</h1>
        </Fade>
      </div>

      <div className={styles.row}>
        <Fade left>
          {/* Card 8 */}
          <div className={styles.column}>
            <Link to="lime-green-suite">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={limeGreen} alt="Lime Green Suite"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextLong}>Lime Green Suite</div>
                    <div className={styles.bottomText}>
                      klcc, jalan sultan ismail
                    </div>
                    {/* SOLD tag*/}
                    <div className={styles.sold}>SOLD</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Fade>
        <Fade right>
          {/* Card 9 */}
          <div className={styles.column}>
            <Link to="luxury-blue-suite">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={luxuryBlue} alt="Luxury Blue Suite"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextLong}>Luxury Blue Suite</div>
                    <div className={styles.bottomText}>vortex klcc</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Fade>
      </div>
    </React.Fragment>
  );
}
