//LUXURY BLUE SUITE
import React, { Component } from "react";

//Slideshow with Thumbnail API + CSS
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";

//CSS
import "./Slideshow.scss";

//IMAGES
import image1 from "../../images/KLCC/luxuryBlueSuite/LB-1.jpg";
import image2 from "../../images/KLCC/luxuryBlueSuite/LB-2.jpg";
import image3 from "../../images/KLCC/luxuryBlueSuite/LB-3.jpg";
import image4 from "../../images/KLCC/luxuryBlueSuite/LB-4.jpg";
import image5 from "../../images/KLCC/luxuryBlueSuite/LB-5.jpg";
import image6 from "../../images/KLCC/luxuryBlueSuite/LB-6.jpg";
import image7 from "../../images/KLCC/luxuryBlueSuite/LB-7.jpg";
import image8 from "../../images/KLCC/luxuryBlueSuite/LB-8.jpg";
import image9 from "../../images/KLCC/luxuryBlueSuite/LB-9.jpg";
import image10 from "../../images/KLCC/luxuryBlueSuite/LB-10.jpg";
import image11 from "../../images/KLCC/luxuryBlueSuite/LB-11.jpg";
import image12 from "../../images/KLCC/luxuryBlueSuite/LB-12.jpg";
import image13 from "../../images/KLCC/luxuryBlueSuite/LB-13.jpg";
import image14 from "../../images/KLCC/luxuryBlueSuite/LB-14.jpg";
import image15 from "../../images/KLCC/luxuryBlueSuite/LB-15.jpg";
import image16 from "../../images/KLCC/luxuryBlueSuite/LB-16.jpg";

const images = [
  {
    original: image1,
    thumbnail: image1,
  },
  {
    original: image2,
    thumbnail: image2,
  },
  {
    original: image3,
    thumbnail: image3,
  },
  {
    original: image4,
    thumbnail: image4,
  },
  {
    original: image5,
    thumbnail: image5,
  },
  {
    original: image6,
    thumbnail: image6,
  },
  {
    original: image7,
    thumbnail: image7,
  },
  {
    original: image8,
    thumbnail: image8,
  },
  {
    original: image9,
    thumbnail: image9,
  },
  {
    original: image10,
    thumbnail: image10,
  },
  {
    original: image11,
    thumbnail: image11,
  },
  {
    original: image12,
    thumbnail: image12,
  },
  {
    original: image13,
    thumbnail: image13,
  },
  {
    original: image14,
    thumbnail: image14,
  },
  {
    original: image15,
    thumbnail: image15,
  },
  {
    original: image16,
    thumbnail: image16,
  },
];

export class SlideshowLB extends Component {
  render() {
    return (
      <React.Fragment>
        <ImageGallery
          items={images}
          showPlayButton={false}
          showBullets={true}
          showIndex={true}
        />
      </React.Fragment>
    );
  }
}

export default SlideshowLB;
