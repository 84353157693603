import React from "react";
import styles from "./Elements.module.scss";
import { Link } from "react-router-dom";

//React Reveal
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

//IMAGES
//Header Image
import headerImage from "../../images/Elements/greenSuite/GS-2.jpg";
//Elements
import blueSuite from "../../images/Elements/blueSuite/BS-11.jpg";
import greenSuite from "../../images/Elements/greenSuite/GS-6.jpg";
import redSuite from "../../images/Elements/redSuite/RS-7.jpg";
import yellowSuite from "../../images/Elements/yellowSuite/YS-4.jpg";
export default function Elements() {
  return (
    <React.Fragment>
      {/* Image Header */}
      <div className={styles.imageHeader}>
        <Zoom>
          <img src={headerImage} alt="Elements" />
        </Zoom>
        <Fade left>
          <h1 className={styles.imageText}>the elements</h1>
        </Fade>
      </div>

      <div className={styles.row}>
        {/* Card 1 */}
        <Fade left>
          <div className={styles.column}>
            <Link to="blue-suite">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={blueSuite} alt="Blue Suite"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextShort}>Blue Suite</div>
                    <div className={styles.bottomText}>The Elements</div>
                    {/* SOLD tag*/}
                    <div className={styles.sold}>SOLD</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Fade>

        <Fade top>
          {/* Card 2 */}
          <div className={styles.column}>
            <Link to="green-suite">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={greenSuite} alt="Green Suite"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextShort}>Green Suite</div>
                    <div className={styles.bottomText}>The Elements</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Fade>

        <Fade bottom>
          {/* Card 3 */}
          <div className={styles.column}>
            <Link to="red-suite">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={redSuite} alt="Red Suite"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextShort}>Red Suite</div>
                    <div className={styles.bottomText}>The Elements</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Fade>

        <Fade right>
          {/* Card 4 */}
          <div className={styles.column}>
            <Link to="yellow-suite">
              <div className={styles.cards}>
                <div className={styles.imagesContainer}>
                  <img src={yellowSuite} alt="Yellow Suite"></img>
                  <div className={styles.overlay}>
                    <div className={styles.topTextShort}>Yellow Suite</div>
                    <div className={styles.bottomText}>The Elements</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Fade>
      </div>
    </React.Fragment>
  );
}
