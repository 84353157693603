import React, { Component } from "react";
import styles from "./Error.module.scss";
import sadImg from "../../images/Others/error.gif";
import NavbarOther from "../../components/Navbar/NavbarOther";
import { Link } from "react-router-dom";
import { AiOutlineSmile } from "react-icons/ai";

export class Error extends Component {
  render() {
    return (
      <div className={styles.container}>
        <NavbarOther />
        <img className={styles.emoji} src={sadImg} alt="Sad Emoji" />
        <h1 className={styles.header}>Ooops! 404</h1>
        <p className={styles.semiHeader}>Page Not Found! </p>
        <p className={styles.text}>
          The page you are looking for doesnt exist, or has been removed. Do
          check to see if the URL you entered is correct
        </p>
        <Link className={styles.button} to="/">
          Lets Go Home <AiOutlineSmile />
        </Link>
      </div>
    );
  }
}

export default Error;
